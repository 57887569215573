<template>
  <b-card class="p-2 rounded-lg" no-body>
    <div class="d-flex align-items-center mb-2" style="gap: 1rem">
      <div class="p-1 rounded" :class="skin == 'dark' ? 'bg-dark' : 'bg-light'">
        <feather-icon icon="ListIcon" size="30" />
      </div>
      <span class="h2 mr-5" :class="skin == 'dark' ? 'text-white' : ''"
        >Weekly Productivity</span
      >
      <b-button
        variant="outline-primary"
        class="ml-5"
        size="sm"
        @click="changeWeek(weekSelected - 1)"
        :disabled="disablePrevWeek"
      >
        <feather-icon icon="ChevronLeftIcon" size="20" />
      </b-button>
      <h2 class="ml-auto my-0">Week {{ weekSelected }}</h2>
      <b-button
        variant="outline-primary"
        class="ml-auto"
        size="sm"
        @click="changeWeek(weekSelected + 1)"
        :disabled="disablePostWeek"
      >
        <feather-icon icon="ChevronRightIcon" size="20" />
      </b-button>
    </div>
    <b-overlay :show="isLoading" :variant="skin">
      <VueApexCharts
        width="100%"
        height="300"
        :options="pieOpts"
        :series="pieOpts.series"
      />
    </b-overlay>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import useBaseDate from "../useBaseDate";
import dashboardService from "../dashboard.service";

export default {
  components: { VueApexCharts },
  data: () => ({
    pieOpts: {
      chart: { type: "pie", toolbar: { show: false }, background: "#0000" },
      theme: { mode: "dark" },
      series: [60, 40, 60, 40, 60, 40, 60],
      colors: [
        "#040180",
        "#cf8b32",
        "#5C527F",
        "#882db4",
        "#30beba",
        "#9a2b41",
        "#5d5232",
      ],
      labels: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      legend: {
        labels: {
          colors: "rgb(160 160 161)",
          useSeriesColors: false,
        },
      },
      xaxis: {
        labels: {
          style: {
            colors: undefined,
            cssClass: "colord",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: undefined,
            cssClass: "colord",
          },
        },
      },
    },
    isLoading: false,
    currentWeek: moment().week(),
    weekSelected: moment().week(),
  }),
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser", skin: "appConfig/skin" }),
    disablePostWeek() {
      return this.weekSelected >= this.currentWeek;
    },
    disablePrevWeek() {
      return this.weekSelected <= 1;
    },
  },
  watch: {
    selected(val) {
      this.getData();
    },
  },
  methods: {
    changeWeek(week) {
      this.weekSelected = week;
      this.getData();
    },
    async getData() {
      let body = {
        agent_id: this.currentUser.user_id,
        base_date: this.selected,
        is_department: this.currentUser.role_id != 15,
        week: this.weekSelected,
      };
      this.isLoading = true;
      let data = await dashboardService.getWeeklyProductivity(body);
      let labels = [];
      let values = [];
      data.forEach((val) => {
        labels.push(val.work_day);
        values.push(val.productivity);
      });
      this.pieOpts.labels = labels;
      this.pieOpts.series = values;
      this.isLoading = false;
    },
  },
  created() {
    this.pieOpts.theme.mode = this.skin;
    this.getData();
  },
  setup() {
    const { selected } = useBaseDate();
    return { selected };
  },
};
</script>
<style>
.colord {
  fill: rgb(160 160 161);
  text-shadow: #fffbfb 0px 0 1px;
}
</style>
