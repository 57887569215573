<template>
  <b-card class="p-2 rounded-lg" no-body>
    <div class="d-flex align-items-center mb-2" style="gap: 1rem">
      <div class="p-1 rounded" :class="skin == 'dark' ? 'bg-dark' : 'bg-light'">
        <feather-icon icon="CalendarIcon" size="30" />
      </div>
      <span class="h2" :class="skin == 'dark' ? 'text-white' : ''"
        >Monthly Productivity</span
      >
      <div class="d-flex justify-content-end" style="flex: 1">
        <span class="mr-1">Detail</span>
        <b-form-checkbox
          v-model="isCompressed"
          switch
          @change="transformChart"
        />
        Total
      </div>
    </div>
    <b-overlay :show="isLoading" :variant="skin">
      <VueApexCharts
        width="100%"
        height="300"
        :options="pieOpts"
        :series="pieOpts.series"
      />
    </b-overlay>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";

import useBaseDate from "../useBaseDate";
import dashboardService from "../dashboard.service";

export default {
  components: { VueApexCharts },
  data: () => ({
    pieOpts: {
      chart: { type: "line", toolbar: { show: false }, background: "#0000" },
      theme: { mode: "dark" },
      // stroke: { width: 10 },
      series: [
        {
          data: [60, 40, 60, 40, 60, 40, 60, 40, 60, 40, 60, 40],
          name: "Services",
        },
        {
          data: [50, 60, 50, 60, 50, 60, 50, 60, 50, 60, 50, 60],
          name: "NCR",
        },
        {
          data: [50, 60, 50, 60, 50, 50, 50, 60, 50, 60, 50, 60],
          name: "Reconnection",
        },
        {
          data: [50, 60, 50, 60, 50, 30, 50, 60, 50, 60, 50, 60],
          name: "Realtor",
        },
      ],
      colors: ["#040180", "#cf8b32", "#5C527F", "#882db4"],
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      xaxis: {
        labels: {
          colors: "rgb(160 160 161)",
          style: {
            colors: undefined,
            cssClass: "colord",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: undefined,
            cssClass: "colord",
          },
        },
      },
    },
    isCompressed: false,
    rawData: [],
    tempColor: "#040180",
    isLoading: false,
  }),
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser", skin: "appConfig/skin" }),
  },
  watch: {
    selected(val) {
      this.getData();
    },
  },
  methods: {
    async getData() {
      this.isCompressed = false;
      let body = {
        agent_id: this.currentUser.user_id,
        base_date: this.selected,
        is_department: this.currentUser.role_id != 15,
      };
      this.isLoading = true;
      let data = await dashboardService.getMonthlyProductivity(body);
      data = data.map((row) => {
        let mNum = new Date(Date.parse(row.month + " 1, 2000")).getMonth() + 1;
        return { ...row, month_n: mNum };
      });
      data.sort((a, b) => a.month_n - b.month_n);
      this.rawData = data; // stores raw data for chart changes
      let series = [
        {
          name: "Add/Change Service",
          data: data.map((val) => val.services),
        },
        {
          name: "NCR",
          data: data.map((val) => val.ncr),
        },
        {
          name: "Realtor",
          data: data.map((val) => val.realtor),
        },
        {
          name: "Reconnection",
          data: data.map((val) => val.reconnections),
        },
      ];
      this.pieOpts.series = series;
      this.transformChart();
      this.isLoading = false;
    },
    transformChart() {
      if (this.isCompressed) {
        let series = [
          {
            name: "Productivity",
            data: this.rawData.map(
              (val) => val.services + val.ncr + val.realtor + val.reconnections
            ),
          },
        ];
        // this.pieOpts.chart = {
        //   type: "bar",
        //   toolbar: { show: false },
        //   background: "#0000",
        // };
        this.pieOpts.colors[0] = "#6a994e";
        this.pieOpts.stroke = { width: 10, curve: "straight" };
        this.pieOpts.series = series;
        this.pieOpts = Object.assign({}, this.pieOpts);
        return;
      }
      let series = [
        {
          name: "Add/Change Service",
          data: this.rawData.map((val) => val.services),
        },
        {
          name: "NCR",
          data: this.rawData.map((val) => val.ncr),
        },
        {
          name: "Realtor",
          data: this.rawData.map((val) => val.realtor),
        },
        {
          name: "Reconnection",
          data: this.rawData.map((val) => val.reconnections),
        },
      ];
      this.pieOpts.colors[0] = this.tempColor;
      this.pieOpts.stroke = { width: 5, curve: "smooth" };
      this.pieOpts.series = series;
      this.pieOpts = Object.assign({}, this.pieOpts);
    },
  },
  created() {
    this.pieOpts.theme.mode = this.skin;
    this.getData();
  },
  setup() {
    const { selected } = useBaseDate();
    return { selected };
  },
};
</script>
<style>
.colord {
  fill: rgb(160 160 161);
  text-shadow: #fffbfb 0px 0 1px;
}
</style>
