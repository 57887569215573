<template>
  <div>
    <b-row class="mb-2">
      <b-col>
        <b-input-group prepend="Year">
          <b-form-select
            :value="selected.substring(0, 4)"
            :options="options.years"
            @change="setSelected($event, 'y')"
          />
        </b-input-group>
      </b-col>
      <b-col>
        <b-input-group prepend="Month">
          <b-form-select
            :value="selected.substring(5, 7)"
            :options="options.months"
            @change="setSelected($event, 'm')"
          />
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="8">
        <b-card class="p-2 rounded-lg" no-body>
          <div class="d-flex align-items-center mb-2" style="gap: 1rem">
            <div
              class="p-1 rounded"
              :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
            >
              <feather-icon icon="LayersIcon" size="30" />
            </div>
            <span class="h2" :class="skin == 'dark' ? 'text-white' : ''"
              >Productivity Department</span
            >
          </div>
          <b-overlay :show="loadingDepartmentBar" :variant="skin">
            <VueApexCharts
              width="100%"
              height="300"
              :options="barDepartmentOpts"
              :series="barDepartmentOpts.series"
            />
          </b-overlay>
        </b-card>
      </b-col>
      <b-col lg="4">
        <MonthlyProduct />
      </b-col>
      <b-col lg="6">
        <AgentsChart />
      </b-col>
      <b-col lg="6">
        <WeeklyChart />
      </b-col>
      <b-col lg="6">
        <Commissions />
      </b-col>
      <b-col lg="6">
        <WorkingHours />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import VueApexCharts from "vue-apexcharts"

import dashboardService from "../dashboard.service"
import useBaseDate from "../useBaseDate"
import AgentsChart from "./AgentsChart.vue"
import MonthlyChart from "./MonthlyChart.vue"
import WeeklyChart from "./WeeklyChart.vue"
import Commissions from "./Commission.vue"
import WorkingHours from "./WorkingHours.vue"
import MonthlyProduct from "./MonthlyProduct.vue"
export default {
  components: {
    VueApexCharts,
    AgentsChart,
    MonthlyChart,
    WeeklyChart,
    Commissions,
    WorkingHours,
    MonthlyProduct,
  },
  data: () => ({
    barDepartmentOpts: {
      chart: { type: "bar", toolbar: { show: false }, background: "#0000" },
      theme: { mode: "dark" },
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      colors: ["#040180", "#cf8b32", "#5C527F", "#882db4"],
      series: [
        {
          name: "Add/Change Service",
          data: [45, 52, 38, 45, 19, 23, 2, 15, 32, 12, 26, 63],
        },
        {
          name: "NCR",
          data: [45, 52, 38, 45, 19, 23, 2, 15, 32, 12, 26, 63],
        },
        {
          name: "Realtor",
          data: [45, 52, 38, 45, 19, 23, 2, 15, 32, 12, 26, 63],
        },
        {
          name: "Reconnection",
          data: [45, 52, 38, 45, 19, 23, 2, 15, 32, 12, 26, 63],
        },
      ],
      legend: {
        labels: {
          colors: "rgb(160 160 161)",
          useSeriesColors: false,
        },
      },
      xaxis: {
        labels: {
          style: {
            colors: undefined,
            cssClass: "colord",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: undefined,
            cssClass: "colord",
          },
        },
      },
    },
    loadingDepartmentBar: false,
  }),
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser", skin: "appConfig/skin" }),
  },
  watch: {
    selected(val) {
      this.getDepartmentProd()
    },
  },
  methods: {
    async getDepartmentProd() {
      let body = {
        base_date: this.selected,
      }
      this.loadingDepartmentBar = true
      let data = await dashboardService.getDepartmentProductivity(body)
      // ordering array elements with month index
      data = data.map((row) => {
        let mNum = new Date(Date.parse(row.month + " 1, 2000")).getMonth() + 1
        return { ...row, month_n: mNum }
      })
      data.sort((a, b) => a.month_n - b.month_n)
      let series = [
        {
          name: "Add/Change Service",
          data: data.map((val) => val.services),
        },
        {
          name: "NCR",
          data: data.map((val) => val.ncr),
        },
        {
          name: "Realtor",
          data: data.map((val) => val.realtor),
        },
        {
          name: "Reconnection",
          data: data.map((val) => val.reconnections),
        },
      ]
      this.barDepartmentOpts.series = series
      this.loadingDepartmentBar = false
    },
  },
  created() {
    this.barDepartmentOpts.theme.mode = this.skin
    this.getDepartmentProd()
  },
  setup() {
    const { options, selected, setSelected } = useBaseDate()
    return { options, selected, setSelected }
  },
}
</script>
<style>
.colord {
  fill: rgb(160 160 161);
  text-shadow: #fffbfb 0px 0 1px;
}
</style>
