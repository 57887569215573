<template>
  <div>
    <b-row>
      <b-col lg="6">
        <AgentsChart />
      </b-col>
      <b-col lg="6">
        <WeeklyChart />
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6">
        <Commissions />
      </b-col>
      <b-col lg="6">
        <WorkingHours />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";

import AgentsChart from "./AgentsChart.vue";
import WeeklyChart from "./WeeklyChart.vue";
import Commissions from "./Commission.vue";
import WorkingHours from "./WorkingHours.vue";

export default {
  components: {
    VueApexCharts,
    AgentsChart,
    WeeklyChart,
    Commissions,
    WorkingHours,
  },
  data: () => ({
    barOpts: {
      chart: { type: "bar", toolbar: { show: false }, background: "#0000" },
      theme: { mode: "dark" },
      series: [
        {
          name: "Series 1",
          data: [45, 52, 38, 45, 19, 23, 2, 15, 32, 12, 26, 63],
        },
      ],
    },
  }),
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser", skin: "appConfig/skin" }),
  },
};
</script>