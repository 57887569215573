<template>
  <div>
    <b-card class="p-2 rounded-lg" no-body>
      <div class="d-flex align-items-center mb-2" style="gap: 1rem">
        <div
          class="p-1 rounded"
          :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
        >
          <feather-icon icon="DollarSignIcon" size="30" />
        </div>
        <span class="h2" :class="skin == 'dark' ? 'text-white' : ''"
          >Commissions</span
        >
      </div>
      <b-overlay :show="false" :variant="skin">
        <div
          class="d-flex justify-content-between"
          style="gap: 2rem; height: 21.5rem"
        >
          <div
            class="d-flex flex-column justify-content-center w-100 p-2 rounded text-center"
            :class="[skin == 'dark' ? 'bg-dark' : 'bg-light']"
          >
            <strong>
              <span
                class="mb-1 font_size"
                :class="isDarkSkin ? 'text-warning' : 'text-primary'"
                >TOTAL</span
              >
            </strong>
            <span style="font-size: 2rem">$ {{ total }}</span>
          </div>
          <div class="d-flex flex-column justify-content-between w-100">
            <div
              class="d-flex flex-column justify-content-center p-4 text-center"
            >
              <strong>
                <span
                  class="mb-1"
                  :class="isDarkSkin ? 'text-warning' : 'text-primary'"
                  >OTHERS</span
                >
              </strong>
              <span
                class="mont_reconection font_size"
                @click="openModalCommissions('charge')"
                >$ {{ addAmount.toFixed(2) }}</span
              >
            </div>
            <div
              class="d-flex flex-column justify-content-center p-4 text-center"
            >
              <strong>
                <span
                  class="mb-1"
                  :class="isDarkSkin ? 'text-warning' : 'text-primary'"
                  >RECONNECTION</span
                >
              </strong>
              <span
                :class="isAgent ? 'mont_reconection' : ''"
                class="font_size"
                @click="openModalCommissions('actions')"
                >$ {{ reconnectionAmount.toFixed(2) }}</span
              >
            </div>
          </div>
        </div>
      </b-overlay>
    </b-card>

    <CommisionsList
      v-if="showModalCommisions"
      :info="modalCommisionsInfo"
      @closing="showModalCommisions = false"
    />

    <detail-commission
      v-if="OpenModalDetail"
      @hideModal="hideModal"
      :agent="agent"
      :detail="detail"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import useBaseDate from "../useBaseDate";
import dashboardService from "../dashboard.service";
import CommissionsService from "@/views/commons/components/commissions/services/commissions.service";

import CommisionsList from "@/views/ce-digital/sub-modules/customer-service/views/dashboard-agent/components/CommisionsList.vue";
import DetailCommission from "@/views/ce-digital/sub-modules/customer-service/views/user-commission/DetailCommission.vue";

export default {
  components: {
    CommisionsList,
    DetailCommission,
  },
  data: () => ({
    changeAmount: 0,
    addAmount: 0,
    reconnectionAmount: 0,
    isLoading: false,
    showModalCommisions: false,
    modalCommisionsInfo: {},
    OpenModalDetail: false,
    agent: null,
    detail: {},
  }),
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser", skin: "appConfig/skin" }),
    total() {
      return (
        this.changeAmount +
        this.addAmount +
        this.reconnectionAmount
      ).toFixed(2);
    },
  },
  watch: {
    selected(val) {
      this.getData();
    },
  },
  methods: {
    async getData() {
      let body = {
        agent_id: this.currentUser.user_id,
        base_date: this.selected,
      };
      this.isLoading = true;
      let data = await dashboardService.getAgentCommissions(body);
      this.reconnectionAmount = data.reduce((acc, val) => {
        if (val.com_type == "HOLD") return acc + Number(val.amount);
        return acc;
      }, 0);
      this.addAmount = data.reduce((acc, val) => {
        if (val.com_type != "HOLD") return acc + Number(val.amount);
        return acc;
      }, 0);
      const params = {
        user: this.currentUser.user_id,
        role: this.currentUser.role_id,
        module: 20,
        year: this.selected.split("-")[0],
        month: this.selected.split("-")[1],
      };
      let resAC = await CommissionsService.searchCommissionsUserDepartment(
        params
      );
      let acTotal = resAC.reduce((a, b) => a + Number(b.commission), 0);
      let percent = {
        14: 0.5,
        2: 0.15,
        15: 0.8,
        1: 0,
        17: 0,
      };
      this.addAmount =
        this.addAmount + acTotal * percent[this.currentUser.role_id];
      this.isLoading = false;
    },
    openModalCommissions(type) {
      if (type == "charge") {
        //Modal charge
        this.agent = {
          id: this.isSupervisor ? 0 : this.currentUser.user_id,
          role_id: this.currentUser.role_id,
        };
        this.detail = { base_date: this.selected };
        this.OpenModalDetail = true;
      } else if (this.isAgent) {
        //Modal Agent
        this.modalCommisionsInfo = {
          type: type, // "actions,charges,services"
          agent_id: this.currentUser.user_id,
          base_date: this.selected,
        };
        this.showModalCommisions = true;
      }
    },
    hideModal() {
      this.OpenModalDetail = false;
    },
  },
  created() {
    this.getData();
  },
  setup() {
    const { selected } = useBaseDate();
    return { selected };
  },
};
</script>
<style>
.mont_reconection {
  cursor: pointer;
}
.font_size {
  font-size: 1.5rem;
}
</style>
