<template>
  <div>
    <b-row class="mb-2">
      <b-col>
        <b-input-group prepend="Year">
          <b-form-select
            :value="selected.substring(0, 4)"
            :options="options.years"
            @change="setSelected($event, 'y')"
          />
        </b-input-group>
      </b-col>
      <b-col>
        <b-input-group prepend="Month">
          <b-form-select
            :value="selected.substring(5, 7)"
            :options="options.months"
            @change="setSelected($event, 'm')"
          />
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6">
        <MonthlyChart />
      </b-col>
      <b-col lg="6">
        <WeeklyChart />
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6">
        <Commissions />
      </b-col>
      <b-col lg="6">
        <WorkingHours />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";

import useBaseDate from "../useBaseDate";
import MonthlyChart from "./MonthlyChart.vue";
import WeeklyChart from "./WeeklyChart.vue";
import Commissions from "./Commission.vue";
import WorkingHours from "./WorkingHours.vue";

export default {
  components: {
    VueApexCharts,
    MonthlyChart,
    WeeklyChart,
    Commissions,
    WorkingHours,
  },
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser", skin: "appConfig/skin" }),
  },
  setup() {
    const { options, selected, setSelected } = useBaseDate();
    return { options, selected, setSelected };
  },
};
</script>