<template>
  <b-card class="p-2 rounded-lg" no-body>
    <div class="d-flex align-items-center mb-2" style="gap: 1rem">
      <div class="p-1 rounded" :class="skin == 'dark' ? 'bg-dark' : 'bg-light'">
        <feather-icon icon="UsersIcon" size="30" />
      </div>
      <span class="h2" :class="skin == 'dark' ? 'text-white' : ''"
        >Agent Productivity</span
      >
    </div>
    <b-overlay :show="isLoading" :variant="skin">
      <VueApexCharts
        width="100%"
        height="300"
        :options="barOpts"
        :series="barOpts.series"
      />
    </b-overlay>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";

import useBaseDate from "../useBaseDate";
import dashboardService from "../dashboard.service";

export default {
  components: { VueApexCharts },
  data: () => ({
    barOpts: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: { show: false },
        background: "#0000",
      },
      theme: { mode: "dark" },
      plotOptions: { bar: { horizontal: true } },
      labels: ["agent1", "agent2", "agent3", "agent4", "agent5", "agent6"],
      colors: ["#cf8b32", "#040180", "#882db4", "#5C527F"],
      series: [
        {
          name: "NCR",
          data: [45, 52, 38, 45, 19, 23],
        },
        {
          name: "Add/Change Services",
          data: [55, 32, 28, 62, 29, 13],
        },
        {
          name: "Reconnection",
          data: [45, 52, 38, 45, 19, 23],
        },
      ],
      legend: {
          labels: {
            colors: 'rgb(160 160 161)',
            useSeriesColors: false
        },
      },
      xaxis: {
        labels: {
          style: {
            colors:undefined,
            cssClass: 'colord',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
              colors: undefined,
              cssClass: 'colord',
          },
        }
      }
    },
    isLoading: false,
  }),
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser", skin: "appConfig/skin" }),
  },
  watch: {
    selected(val) {
      this.getData();
    },
  },
  methods: {
    async getData() {
      let body = {
        base_date: this.selected,
      };
      this.isLoading = true;
      let data = await dashboardService.getAgentsProductivity(body);
      let series = [
        {
          name: "NCR",
          data: data.map((val) => val.ncr),
        },
        {
          name: "Add/Change Services",
          data: data.map((val) => val.services),
        },
        {
          name: "Reconnection",
          data: data.map((val) => val.reconnections),
        },
      ];
      this.barOpts.labels = data.map((val) => val.agent_name);
      this.barOpts.series = series;
      this.barOpts = Object.assign({}, this.barOpts);

      this.isLoading = false;
    },
  },
  created() {
    this.barOpts.theme.mode = this.skin;
    this.getData();
  },
  setup() {
    const { selected } = useBaseDate();
    return { selected };
  },
};
</script>
<style>
  .colord{
      fill: rgb(160 160 161);
      text-shadow:#fffbfb 0px 0 1px;
  }
</style>