<template>
  <b-card class="p-2 rounded-lg" no-body>
    <div class="d-flex align-items-center mb-2" style="gap: 1rem">
      <div class="p-1 rounded" :class="skin == 'dark' ? 'bg-dark' : 'bg-light'">
        <feather-icon icon="ClockIcon" size="30" />
      </div>
      <span class="h2" :class="skin == 'dark' ? 'text-white' : ''"
        >Monthly Productivity
      </span>
    </div>
    <b-overlay :show="loadingAmount" :variant="skin">
      <b-table
        show-empty
        sticky-header="22.2rem"
        style="height: 22.2rem"
        :items="items"
        :fields="fields"
      >
        <template #cell(type)="data">
          <b-badge :variant="badgeStyle(data.value)">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(amount_total)="data"> $ {{ data.value }} </template>
      </b-table>
    </b-overlay>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex"
import moment from "moment"
import ModalAgentDetail from "@/views/ce-digital/sub-modules/customer-service/views/agent-away/components/ModalAgentDetail.vue"
import dashboardService from "../dashboard.service"
import useBaseDate from "../useBaseDate"

export default {
  components: { ModalAgentDetail },
  data: () => ({
    fields: [
      { key: "type" },
      { key: "amount_total", tdClass: "text-center", thClass: "text-center" },
    ],
    loadingAmount: true,
    items: [
      {
        key: "add_change",
        type: "ADD/CHANGE",
        amount_total: "0.00",
      },
      {
        key: "ncr",
        type: "NCR",
        amount_total: "0.00",
      },
      {
        key: "realtor",
        type: "REALTOR",
        amount_total: "0.00",
      },
      {
        key: "reconnect",
        type: "RECONNECTION",
        amount_total: "0.00",
      },
    ],
  }),
  watch: {
    selected(val) {
      this.getAmounts()
    },
  },
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser", skin: "appConfig/skin" }),
  },
  methods: {
    badgeStyle(status) {
      switch (status) {
        case "ADD/CHANGE":
          return "light-primary"
        case "NCR":
          return "light-warning"
        case "REALTOR":
          return "light-secondary"
        case "RECONNECTION":
          return "light-info"
        default:
          return "light-secondary"
      }
    },
    async getAmounts() {
      let body = {
        base_date: this.selected,
      }
      const res = await dashboardService.getDepartmentProductivityAmount(body)
      this.loadingAmount = false
      res.map((r) => {
        this.items.map((item2) => {
          if (item2.key == r.name) {
            item2.amount_total = r.amount
          }
        })
      })
    },
  },
  created() {
    this.getAmounts()
    this.loadingAmount = false
  },
  setup() {
    const { selected } = useBaseDate()
    return { selected }
  },
}
</script>
