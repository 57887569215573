<template>
  <div>
    <header-slot> </header-slot>
    <component :is="roleDashboard" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import DsSuper from "./components/Super.vue";
import DsAgent from "./components/Agent.vue";
import DsAssistant from "./components/Assistant.vue";

export default {
  components: { DsSuper, DsAgent, DsAssistant },
  data: () => ({
    foo: null,
  }),
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser", skin: "appConfig/skin" }),
    roleDashboard() {
      switch (this.currentUser.role_id) {
        case 2:
          return "DsSuper";
        case 1:
          return "DsSuper";
        case 17:
          return "DsSuper";
        case 14:
          return "DsAssistant";
        case 15:
          return "DsAgent";
        default:
          return "";
      }
    },
  },
};
</script>