<template>
  <b-card class="p-2 rounded-lg" no-body>
    <div class="d-flex align-items-center mb-2" style="gap: 1rem">
      <div class="p-1 rounded" :class="skin == 'dark' ? 'bg-dark' : 'bg-light'">
        <feather-icon icon="ClockIcon" size="30" />
      </div>
      <span class="h2" :class="skin == 'dark' ? 'text-white' : ''"
        >Working Hours {{ isSup ? "(All Department)" : "" }}</span
      >
    </div>
    <b-overlay :show="hoursLoading" :variant="skin">
      <b-form-group class="mb-2" label="Agents" label-for="sAgent" v-if="isSup">
        <b-form-select
          id="sAgent"
          v-model="agentSelected"
          :options="agentOpts"
          @change="showAgentDetail($event)"
        />
      </b-form-group>
      <div class="d-flex justify-content-between mb-2" v-else>
        <div class="text-center">
          <span class="d-block h6 text-success"><u>Worked time</u></span>
          <span class="h2 text-success">{{ formatSeconds(workedTime) }}</span>
        </div>
        <b-button
          small
          variant="flat-primary"
          class="btn-icon"
          @click="showModalAgentDetail = true"
        >
          <feather-icon icon="EyeIcon" />
        </b-button>
        <div class="text-center">
          <span class="d-block h6 text-danger"><u>Dead time</u></span>
          <span class="h2 text-danger">{{ formatSeconds(deadTime) }}</span>
        </div>
      </div>
      <b-table
        show-empty
        sticky-header="15rem"
        style="height: 15rem"
        :items="hoursItems"
        :fields="fields"
      >
        <template #cell(status)="data">
          <b-badge :variant="badgeStyle(data.value)">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(total_duration)="data">{{
          formatSeconds(data.value, true)
        }}</template>
      </b-table>
    </b-overlay>
    <ModalAgentDetail
      :show="showModalAgentDetail"
      :id="infoModalAgentDetail.id"
      :agentName="infoModalAgentDetail.name"
      @closing="showModalAgentDetail = false"
    />
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

import agentService from "../dashboard.service";
import ModalAgentDetail from "@/views/ce-digital/sub-modules/customer-service/views/agent-away/components/ModalAgentDetail.vue";

export default {
  components: { ModalAgentDetail },
  data: () => ({
    fields: [{ key: "status" }, { key: "total_duration" }],
    hoursItems: [],
    deadTime: 0,
    workedTime: 0,
    hoursLoading: false,
    showModalAgentDetail: false,
    infoModalAgentDetail: {},
    agentOpts: [],
    agentSelected: null,
  }),
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser", skin: "appConfig/skin" }),
    isSup() {
      return this.currentUser.role_id != 15;
    },
  },
  methods: {
    async getWorkingHours() {
      let body = {
        agent_id: this.currentUser.user_id,
        base_date: moment().format("YYYY-MM-DD"),
      };
      if (this.isSup) {
        body.is_department = true;
      }
      this.hoursLoading = true;
      let data = await agentService.getWorkingHours(body);
      this.deadTime = data.reduce((acc, val) => {
        if (val.status == "BUSY" || val.status == "AWAY")
          return acc + Number(val.total_duration);
        return acc;
      }, 0);
      this.workedTime = data.reduce((acc, val) => {
        if (val.status == "ACTIVE" || val.status == "IN CALL")
          return acc + Number(val.total_duration);
        return acc;
      }, 0);
      this.hoursItems = data;
      this.hoursLoading = false;
    },
    formatSeconds(seconds, withSec = false) {
      seconds = Number(seconds);
      if (!seconds) return "Without registers";
      let sec = seconds % 60;
      if (sec < 10) sec = "0" + sec;
      let min = Math.floor(seconds / 60);
      min = min % 60;
      if (min < 10) min = "0" + min;
      let hour = Math.floor(seconds / 3600);
      if (hour < 10) hour = "0" + hour;

      if (withSec) return `${hour} hours, ${min} minutes, ${sec} seconds`;
      return `${hour} hours, ${min} minutes`;
    },
    badgeStyle(status) {
      switch (status) {
        case "ACTIVE":
          return "light-success";
        case "BUSY":
          return "light-warning";
        case "AWAY":
          return "light-danger";
        case "IN CALL":
          return "light-info";
        default:
          return "light-secondary";
      }
    },
    showAgentDetail(e) {
      let match = this.agentOpts.find((agent) => agent.value == e);
      if (match == undefined) console.error("No agent match");
      this.infoModalAgentDetail = {
        id: match.value,
        name: match.text,
      };
      this.showModalAgentDetail = true;
    },
    async getAdvisors() {
      let data = await agentService.getAllAgents();
      this.agentOpts = data;
    },
  },
  created() {
    this.infoModalAgentDetail = {
      id: this.currentUser.user_id,
      name: this.currentUser.fullName,
    };
    this.getWorkingHours();
    if (this.isSup) {
      this.getAdvisors();
    }
  },
};
</script>