import { reactive, ref, readonly } from "@vue/composition-api"
import moment from "moment"

let options = reactive({
  years: [],
  months: [
    { value: "01", text: "January" },
    { value: "02", text: "February" },
    { value: "03", text: "March" },
    { value: "04", text: "April" },
    { value: "05", text: "May" },
    { value: "06", text: "June" },
    { value: "07", text: "July" },
    { value: "08", text: "August" },
    { value: "09", text: "September" },
    { value: "10", text: "October" },
    { value: "11", text: "November" },
    { value: "12", text: "December" },
  ]
})

let currYear = new Date().getFullYear()
for (let i = currYear; i > currYear - 5; i--) {
  options.years.push({ value: String(i), text: String(i) })
}

let selected = ref(moment().format("YYYY-MM-DD"))

function setSelected(e, type) {
  switch (type) {
    case "y":
      selected.value = `${e}-${selected.value.substring(5, 7)}-${selected.value.substring(8, 10)}`
      break;
    case "m":
      selected.value = `${selected.value.substring(0, 4)}-${e}-${selected.value.substring(8, 10)}`
      break;
    default:
      return
  }
}


export default function () {
  return {
    options: readonly(options),
    selected: readonly(selected),
    setSelected,
  }
}