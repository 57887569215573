<template>
  <h3>Dashboard</h3>
</template>

<script>
export default {

}
</script>

<style>

</style>